import React from "react"
import Styled from "styled-components"

import { ThreeDots } from "../Fontawesome"

const FullScreen = Styled.div`
    background-color: #000000 !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100vw;
    height: 100vh;
    z-index: 999;
`
const LoaderContainer = Styled.div`
    svg {
        fill: ${({ theme }) => theme.primary};
    }
`

const Loader = ({ isFullScreen = false, message }) => {
  if (isFullScreen) {
    return (
      <FullScreen id="full-screen">
        <LoaderContainer>
          <ThreeDots />
        </LoaderContainer>
      </FullScreen>
    )
  }
  return (
    <LoaderContainer>
      <ThreeDots />
      {message && (
        <>
          <br />
          <span>
            <i>{message}</i>
          </span>
        </>
      )}
    </LoaderContainer>
  )
}

export default Loader
