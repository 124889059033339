import { Link } from "gatsby"
import Styled from "styled-components"

const H1 = Styled.h1`
    font-family: ${({ theme }) => theme.robotoFont};
    text-transform: uppercase;
    text-align: center;
`
const H2 = Styled.h2`
    font-family: ${({ theme }) => theme.robotoFont};
    text-transform: uppercase;
    text-align: center;
`
const H3 = Styled.h3`
    font-family: ${({ theme }) => theme.robotoFont};
    text-transform: uppercase;
    text-align: center;
`
const H4 = Styled.h4`
    font-family: ${({ theme }) => theme.robotoFont};
    text-transform: uppercase;
    text-align: center;
`
const H5 = Styled.h5`
    font-family: ${({ theme }) => theme.robotoFont};
    text-transform: uppercase;
    text-align: center;
`
const H6 = Styled.h6`
    font-family: ${({ theme }) => theme.robotoFont};
    text-transform: uppercase;
    text-align: center;
`
const HR = Styled.hr`
    border-top: ${({ theme }) => `1px solid ${theme.primary}`};
`
const MyLink = Styled(Link)`
    font-family: ${({ theme }) => theme.robotoFont};
    color: ${({ theme }) => theme.white};
    text-transform: uppercase;
    font-weight: 600;
    padding: 22px 9px 24px 9px;
    font-size: 0.9em;
    transition: all 0.2s linear;
    &:hover {
        text-decoration: none;
        color: ${({ theme }) => theme.primary};
    }
`

export { H1, H2, H3, H4, H5, H6, HR, MyLink }
